/**
 * @licencia
 * Copyright 2017 Google LLC
 *
 * Licenciado bajo la Licencia Apache, Versión 2.0 (la "Licencia");
 * no puede utilizar este archivo salvo en cumplimiento de la Licencia.
 * Puede obtener una copia de la licencia en
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * A menos que lo exija la legislación aplicable o se acuerde por escrito, el software
 * distribuido bajo la Licencia se distribuye sobre una base "tal cual",
 * SIN GARANTÍAS NI CONDICIONES DE NINGÚN TIPO, ni expresas ni implícitas.
 * Consulte la Licencia para conocer el lenguaje específico que rige los permisos y
 * limitaciones de la Licencia.
 */
exportar tipo NextFn<t> = (valor: T) => void;
exportar tipo ErrorFn = (error: Error) => void;
exportar tipo CompleteFn = () => void;

interfaz de exportación Observador<t> {
  // Called once for each value in a stream of values.
  next: NextFn<t>;

  // Un flujo termina con una sola llamada a error() o a complete().
  error: ErrorFn;

  // No se enviarán eventos a next() una vez que se llame a complete().
  completo: CompleteFn;
}

exportar tipo PartialObserver<t> = Parcial<observer<t>>;

// TODO: ¿Apoyar también Unsubscribe.unsubscribe?
exportar tipo Unsubscribe = () => void;

/**
 * La interfaz Subscribe tiene dos formas: pasar la función inline
 * devoluciones de llamada, o una interfaz de objeto con propiedades de devolución de llamada.
 */
interfaz de exportación Suscribirse<t> {
  (next?: NextFn<t>error: ErrorFn, completo: CompleteFn): Desinscríbete;
  (observador: PartialObserver<t>): Desinscríbete;
}

exportar interfaz Observable<t> {
  // Subscribe method
  subscribe: Subscribe<t>;
}

tipo de exportación Ejecutor<t> = (observar: Observar<t>) => void;

/**
 * Ayudante para hacer una función Subscribe (al igual que Promise ayuda a hacer una
 * Thenable).
 *
 * @param executor Función que puede hacer llamadas a un solo Observador
 *como representante.
 * @param onNoObservers Callback cuando el recuento de observadores llega a cero.
 */
función de exportación createSubscribe<t>(
  ejecutor: Ejecutor<t>,
  onNoObservers: Ejecutor<t>
): Suscríbase a<t> {
  const proxy = new ObserverProxy<t>(ejecutor, onNoObservers);
  return proxy.subscribe.bind(proxy);
}

/**
 * Implementar el fan-out para cualquier número de observadores conectados a través de una suscripción
 * función.
 */
clase ObserverProxy<t> implementa el Observador<t> {
  private observers: Array<observer<t>| indefinido = [];
  private unsubscribes: Unsubscribe[] = [];
  private onNoObservers: Ejecutor<t> | indefinido;
  private observerCount = 0;
  // Programación de microtareas llamando a task.then().
  private task = Promise.resolve();
  private finalized = false;
  private finalError?: Error;

  /**
   * @param executor Función que puede hacer llamadas a un solo Observador
   *como representante.
   * @param onNoObservers Callback cuando el recuento de observadores llega a cero.
   */
  constructor(ejecutor: Ejecutor<t>onNoObservers: Ejecutor<t>) {
    this.onNoObservers = onNoObservers;
    // Call the executor asynchronously so subscribers that are called
    // synchronously after the creation of the subscribe function
    // can still receive the very first value generated in the executor.
    this.task
      .then(() => {
        executor(this);
      })
      .catch(e => {
        this.error(e);
      });
  }

  next(value: T): void {
    this.forEachObserver((observer: Observer<t>) => {
      observer.next(value);
    });
  }

  error(error: Error): void {
    this.forEachObserver((observer: Observer<t>) => {
      observer.error(error);
    });
    this.close(error);
  }

  complete(): void {
    this.forEachObserver((observer: Observer<t>) => {
      observer.complete();
    });
    this.close();
  }

  /**
   * Subscribe function that can be used to add an Observer to the fan-out list.
   *
   * - We require that no event is sent to a subscriber sychronously to their
   *   call to subscribe().
   */
  subscribe(
    nextOrObserver?: NextFn<t> | PartialObserver<t>,
    error?: ErrorFn,
    complete?: CompleteFn
  ): Unsubscribe {
    let observer: Observer<t>;

    if (
      nextOrObserver === undefined &&
      error === undefined &&
      complete === undefined
    ) {
      throw new Error('Missing Observer.');
    }

    // Assemble an Observer object when passed as callback functions.
    if (
      implementsAnyMethods(nextOrObserver as { [key: string]: unknown }, [
        'next',
        'error',
        'complete'
      ])
    ) {
      observer = nextOrObserver as Observer<t>;
    } else {
      observer = {
        next: nextOrObserver as NextFn<t>,
        error,
        completa
      como Observador<t>;
    }

    if (observer.next === undefined) {
      observer.next = noop as NextFn<t>;
    }
    if (observer.error === undefined) {
      observer.error = noop as ErrorFn;
    }
    if (observer.complete === undefined) {
      observer.complete = noop as CompleteFn;
    }

    const unsub = this.unsubscribeOne.bind(this, this.observers!.length);

    // Attempt to subscribe to a terminated Observable - we
    // just respond to the Observer with the final error or complete
    // event.
    if (this.finalized) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.task.then(() => {
        try {
          if (this.finalError) {
            observer.error(this.finalError);
          } else {
            observer.complete();
          }
        } catch (e) {
          // nothing
        }
        return;
      });
    }

    this.observers!.push(observer as Observer<t>);

    return unsub;
  }

  // Unsubscribe is synchronous - we guarantee that no events are sent to
  // any unsubscribed Observer.
  private unsubscribeOne(i: number): void {
    if (this.observers === undefined || this.observers[i] === undefined) {
      return;
    }

    delete this.observers[i];

    this.observerCount -= 1;
    if (this.observerCount === 0 && this.onNoObservers !== undefined) {
      this.onNoObservers(this);
    }
  }

  private forEachObserver(fn: (observer: Observer<t>) => void): void {
    if (this.finalized) {
      // Already closed by previous event....just eat the additional values.
      return;
    }

    // Since sendOne calls asynchronously - there is no chance that
    // this.observers will become undefined.
    for (let i = 0; i < this.observers!.length; i++) {
      this.sendOne(i, fn);
    }
  }

  // Call the Observer via one of it's callback function. We are careful to
  // confirm that the observe has not been unsubscribed since this asynchronous
  // function had been queued.
  private sendOne(i: number, fn: (observer: Observer<t>) => void): void {
    // Execute the callback asynchronously
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.task.then(() => {
      if (this.observers !== undefined && this.observers[i] !== undefined) {
        try {
          fn(this.observers[i]);
        } catch (e) {
          // Ignore exceptions raised in Observers or missing methods of an
          // Observer.
          // Log error to console. b/31404806
          if (typeof console !== 'undefined' && console.error) {
            console.error(e);
          }
        }
      }
    });
  }

  private close(err?: Error): void {
    if (this.finalized) {
      return;
    }
    this.finalized = true;
    if (err !== undefined) {
      this.finalError = err;
    }
    // Proxy is no longer needed - garbage collect references
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.task.then(() => {
      this.observers = undefined;
      this.onNoObservers = undefined;
    });
  }
}

/** Turn synchronous function into one called asynchronously. */
// eslint-disable-next-line @typescript-eslint/ban-types
export function async(fn: Function, onError?: ErrorFn): Function {
  return (...args: unknown[]) => {
    Promise.resolve(true)
      .then(() => {
        fn(...args);
      })
      .catch((error: Error) => {
        if (onError) {
          onError(error);
        }
      });
  };
}

/**
 * Return true if the object passed in implements any of the named methods.
 */
function implementsAnyMethods(
  obj: { [key: string]: unknown },
  methods: string[]
): boolean {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  for (const method of methods) {
    if (method in obj && typeof obj[method] === 'function') {
      return true;
    }
  }

  return false;
}

function noop(): void {
  // do nothing
}
</t></t></t></t></t></t></t></t></t></t></t></t></t></t></t></t></observer<t></t></t></t></t></t></t></t></t></t></t></t></t></t></t></observer<t></t></t></t></t>