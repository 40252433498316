/**
 * Consume la promesa y registra el error cuando la rechaza.
 * @param promise Una promesa a olvidar.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function forget(promise: PromiseLike<any>): void {
  void promise.then(null, e => {
    // TODO: Use a better logging mechanism
    // eslint-disable-next-line no-console
    console.error(e);
  });
}
</any>