import { Event, EventHint, Options, Session, Severity, Transport } from '@sentry/types';
import { logger, SentryError } from '@sentry/utils';

import { initAPIDetails } from './api';
import { IS_DEBUG_BUILD } from './flags';
import { createEventEnvelope, createSessionEnvelope } from './request';
import { NewTransport } from './transports/base';
import { NoopTransport } from './transports/noop';

/**
 * Internal platform-dependent Sentry SDK Backend.
 *
 * While {@link Client} contains business logic specific to an SDK, the
 * Backend offers platform specific implementations for low-level operations.
 * These are persisting and loading information, sending events, and hooking
 * into the environment.
 *
 * Backends receive a handle to the Client in their constructor. When a
 * Backend automatically generates events, it must pass them to
 * the Client for validation and processing first.
 *
 * Usually, the Client will be of corresponding type, e.g. NodeBackend
 * receives NodeClient. However, higher-level SDKs can choose to instantiate
 * multiple Backends and delegate tasks between them. In this case, an event
 * generated by one backend might very well be sent by another one.
 *
 * The client also provides access to options via {@link Client.getOptions}.
 * @hidden
 */
export interface Backend {
  /** Creates an {@link Event} from all inputs to `captureException` and non-primitive inputs to `captureMessage`. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventFromException(exception: any, hint?: EventHint): PromiseLike<event>;

  /** Creates an {@link Event} from primitive inputs to `captureMessage`. */
  eventFromMessage(message: string, level?: Severity, hint?: EventHint): PromiseLike<event>;

  /** Envía el evento a Sentry */
  sendEvent(event: Event): void;

  /** Envía la sesión a Sentry */
  sendSession(session: Session): void;

  /**
   * Devuelve el transporte que es utilizado por el backend.
   * Tenga en cuenta que el transporte se inicializa de forma perezosa, por lo que sólo estará presente una vez que se haya enviado el primer evento.
   *
   * @returns El transporte.
   */
  getTransport(): El transporte;
}

/**
 * Un objeto de clase que puede instanciar objetos Backend.
 * @hidden
 */
exportar tipo BackendClass<b extends="" Backend,="" O="" Options=""> = new (options: O) => B;

/**
 * Esta es la implementación base de un Backend.
 * @hidden
 */
exportar la clase abstracta BaseBackend<o extends="" Options=""> implements Backend {
  /** Options passed to the SDK. */
  protected readonly _options: O;

  /** Cached transport used internally. */
  protected _transport: Transport;

  /** New v7 Transport that is initialized alongside the old one */
  protected _newTransport?: NewTransport;

  /** Creates a new backend instance. */
  public constructor(options: O) {
    this._options = options;
    if (!this._options.dsn) {
      IS_DEBUG_BUILD && logger.warn('No DSN provided, backend will not do anything.');
    }
    this._transport = this._setupTransport();
  }

  /**
   * @inheritDoc
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  public eventFromException(_exception: any, _hint?: EventHint): PromiseLike<event> {
    throw new SentryError('Backend has to implement `eventFromException` method');
  }

  /**
   * @inheritDoc
   */
  public eventFromMessage(_message: string, _level?: Severity, _hint?: EventHint): PromiseLike<event> {
    throw new SentryError('Backend has to implement `eventFromMessage` method');
  }

  /**
   * @inheritDoc
   */
  public sendEvent(event: Event): void {
    // TODO(v7): Remove the if-else
    if (
      this._newTransport &&
      this._options.dsn &&
      this._options._experiments &&
      this._options._experiments.newTransport
    ) {
      const api = initAPIDetails(this._options.dsn, this._options._metadata, this._options.tunnel);
      const env = createEventEnvelope(event, api);
      void this._newTransport.send(env).then(null, reason => {
        IS_DEBUG_BUILD && logger.error('Error while sending event:', reason);
      });
    } else {
      void this._transport.sendEvent(event).then(null, reason => {
        IS_DEBUG_BUILD && logger.error('Error while sending event:', reason);
      });
    }
  }

  /**
   * @inheritDoc
   */
  public sendSession(session: Session): void {
    if (!this._transport.sendSession) {
      IS_DEBUG_BUILD && logger.warn("Dropping session because custom transport doesn't implement sendSession");
      return;
    }

    // TODO(v7): Remove the if-else
    if (
      this._newTransport &&
      this._options.dsn &&
      this._options._experiments &&
      this._options._experiments.newTransport
    ) {
      const api = initAPIDetails(this._options.dsn, this._options._metadata, this._options.tunnel);
      const [env] = createSessionEnvelope(session, api);
      void this._newTransport.send(env).then(null, reason => {
        IS_DEBUG_BUILD && logger.error('Error while sending session:', reason);
      });
    } else {
      void this._transport.sendSession(session).then(null, reason => {
        IS_DEBUG_BUILD && logger.error('Error while sending session:', reason);
      });
    }
  }

  /**
   * @inheritDoc
   */
  public getTransport(): Transport {
    return this._transport;
  }

  /**
   * Sets up the transport so it can be used later to send requests.
   */
  protected _setupTransport(): Transport {
    return new NoopTransport();
  }
}
</event></event></o></b></event></event>