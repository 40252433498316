/**
 * @licencia
 * Copyright 2021 Google LLC
 *
 * Licenciado bajo la Licencia Apache, Versión 2.0 (la "Licencia");
 * no puede utilizar este archivo salvo en cumplimiento de la Licencia.
 * Puede obtener una copia de la licencia en
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * A menos que lo exija la legislación aplicable o se acuerde por escrito, el software
 * distribuido bajo la Licencia se distribuye sobre una base "tal cual",
 * SIN GARANTÍAS NI CONDICIONES DE NINGÚN TIPO, ni expresas ni implícitas.
 * Consulte la Licencia para conocer el lenguaje específico que rige los permisos y
 * limitaciones en virtud de la Licencia.
 */

interfaz de exportación Compat<t> {
  _delegate: T;
}

export function getModularInstance<expservice>(
  servicio: Compat<expservice> | ExpService
): ExpService {
  if (service && (service as Compat<expservice>)._delegado) {
    retorno (servicio como Compat<expservice>)._delegate;
  } else {
    return service as ExpService;
  }
}
</expservice></expservice></expservice></expservice></t>